export const yearList = [
  2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
  2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035,
  2036, 2037, 2038, 2039, 2040,
];

export const sportList = [
  {
    id: "6399b1cad5ebe005e02058af",
    sportName: "NFL",
  },
  {
    id: "63997142e372acf6f3a9c4f5",
    sportName: "CRICKET",
  },
];

export const sports = {
  1: "Formula Team",
  2: "Formula Car",
  3: "Formula Racer",
  4: "Formula Prix",
};

export const getSportId = (name) => {
  switch (name) {
    case "Formula Team":
      return "1";
    case "Formula Car":
      return "2";
    case "Formula Racer":
      return "3";
    case "Formula Prix":
      return "4";
    default:
      throw new Error(`Unknown sport "${name}"`);
  }
};
