import React from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CollectionsIcon from "@mui/icons-material/Collections";
import AuthContext from "@context/AuthContext";
import PhotoIcon from "@mui/icons-material/Photo";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import logo from "@assets/Images/logo.png";
import logoIcon from "@assets/Images/logoicon.png";
import { useSelector } from "react-redux";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

import av from "src/assets/Images/av.jpg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { imageHandler } from "./utils/formatter";

import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import MovieIcon from "@mui/icons-material/Movie";
import ClickAwayListener from "@mui/base/ClickAwayListener";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 0),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Navbar = ({ children }) => {
  const { user } = useSelector((state) => state.userDetails);
  const theme = useTheme();
  const navigate = useNavigate();
  // const [user, setUser] = useState();
  const [open, setOpen] = React.useState(true);
  const { isUserLogin } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  // Tabs Opan State
  const [entertainmentopen, setEntertainmentOpen] = React.useState(false);
  const [collectibleopen, setCollectibleOpen] = React.useState(true);
  const [aflOpen, setAflOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  // useEffect(() => {
  //   dispatch(getUserDetails());
  // }, [isUserLogin]);

  const handleClickAway = () => {};

  if (!isUserLogin) {
    return <div>{children}</div>;
  } else
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          style={{ backgroundColor: "#4d5268" }}
          className="appbar"
          position="fixed"
          open={open}
        >
          <Toolbar className="header_content">
            <div className="header_logo">
              <IconButton
                style={{ color: "#FFFFFF  " }}
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 1, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={logoIcon}
                style={{
                  height: "48px",
                  background: "#4d5268",
                  cursor: "pointer",
                }}
                alt=""
                onClick={() => navigate("/dashboard")}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img
                className="adminProfile"
                src={user?.profile ? imageHandler(user?.profile, 7) : av}
                alt=""
              />
              <Typography className="username">
                {user?.first_name || "N/A"} {user?.last_name || "N/A"}
              </Typography>
              <Typography
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <div className="username">
                  <ExpandMoreIcon />
                </div>
              </Typography>
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/profile" onClick={handleClose}>
                Profile
              </MenuItem>
              <MenuItem onClick={onSubmit}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader style={{ background: "#4d5268" }}>
            <img
              src={logo}
              style={{
                height: "25px",
                background: "#4d5268",
                cursor: "pointer",
              }}
              alt=""
              onClick={() => navigate("/dashboard")}
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <StyledLink to="/dashboard">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </StyledLink>
            <hr />
            {/* <StyledLink to="/sport-card">
              <ListItemIcon>
                <PhotoIcon />
              </ListItemIcon>
              <ListItemText primary="Sport Card" />
            </StyledLink>
            <hr /> */}

            <ClickAwayListener onClickAway={handleClickAway}>
              <Box sx={{ position: "relative" }}>
                <StyledLink
                  to="/collectible-packs"
                  // onClick={() => setCollectibleOpen(!collectibleopen)}
                >
                  <ListItemIcon>
                    <CollectionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Collectible Packs" />
                  {/* {collectibleopen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )} */}
                </StyledLink>
                <hr />
                {collectibleopen ? (
                  <Box>
                    <StyledLink to="/tournament">
                      <ListItemIcon>
                        <SportsCricketIcon />
                      </ListItemIcon>
                      <ListItemText primary="Tournaments" />
                    </StyledLink>
                    <hr />
                    {/* <StyledLink to="/player">
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="Player" />
                    </StyledLink> */}
                    {/* <hr /> */}
                    <StyledLink to="/teams">
                      <ListItemIcon>
                        <GroupsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Teams" />
                    </StyledLink>
                    <hr />
                  </Box>
                ) : null}
              </Box>
            </ClickAwayListener>
            {/* <StyledLink to="/user-card-ranks">
              <ListItemIcon>
                <MilitaryTechIcon />
              </ListItemIcon>
              <ListItemText primary="User Card Rank" />
            </StyledLink> */}
            {/* <hr /> */}
            {/* <ClickAwayListener onClickAway={handleClickAway}>
              <Box sx={{ position: "relative" }}>
                <StyledLink
                  to="/entertainment-packs"
                  onClick={() => setEntertainmentOpen(!entertainmentopen)}
                >
                  <ListItemIcon>
                    <SportsEsportsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Entertainment Packs" />
                  {entertainmentopen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </StyledLink>
                <hr />
                {entertainmentopen ? (
                  <Box>
                    <StyledLink to="/agency">
                      <ListItemIcon>
                        <AddBusinessIcon />
                      </ListItemIcon>
                      <ListItemText primary="Agency" />
                    </StyledLink>
                    <hr />
                    <StyledLink to="/movies">
                      <ListItemIcon>
                        <MovieIcon />
                      </ListItemIcon>
                      <ListItemText primary="Movies" />
                    </StyledLink>
                    <hr />
                    <StyledLink to="superheroes">
                      <ListItemIcon>
                        <MovieIcon />
                      </ListItemIcon>
                      <ListItemText primary="Super Heroes" />
                    </StyledLink>
                    <hr />
                  </Box>
                ) : null}
              </Box>
            </ClickAwayListener> */}

            {/* <ClickAwayListener onClickAway={handleClickAway}>
              <Box sx={{ position: "relative" }}>
                <StyledLink
                  to="/afl-packs"
                  onClick={() => setAflOpen(!aflOpen)}
                >
                  <ListItemIcon>
                    <CollectionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="AFL Packs" />
                  {aflOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </StyledLink>
                <hr />
                {aflOpen ? (
                  <Box>
                    <StyledLink to="/afl-avatar">
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="AFL Avatar" />
                    </StyledLink>
                    <hr />
                    <StyledLink to="/afl-player">
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="AFL Player" />
                    </StyledLink>
                    <hr />
                    <StyledLink to="/afl-team">
                      <ListItemIcon>
                        <SportsCricketIcon />
                      </ListItemIcon>
                      <ListItemText primary="AFL Team" />
                    </StyledLink>
                    <hr />
                    <StyledLink to="/afl-match">
                      <ListItemIcon>
                        <SportsCricketIcon />
                      </ListItemIcon>
                      <ListItemText primary="AFL Match" />
                    </StyledLink>
                    <hr />
                  </Box>
                ) : null}
              </Box>
            </ClickAwayListener> */}

            {/* <StyledLink to="/earnings-sales">
              <ListItemIcon>
                <PlaylistAddCheckIcon />
              </ListItemIcon>
              <ListItemText primary="Earnings / Sales" />
            </StyledLink> */}
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          {children}
        </Main>
      </Box>
    );
};
export default Navbar;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #27272f;
  display: flex;
  margin-left: 15px;
`;
